import React from 'react';

export default function NotFound() {
  return (
    <div style={{ backgroundColor: '#1c7787' }}>
      <h1>LOL NOPE</h1>
      <p>
        If you see this, you were likely attempting to break my site, so better
        luck next time!
      </p>
    </div>
  );
}
